/* General styling for the contact section */
.contact {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 5vw 10vw;
  background-color: #e8f5e9;
  flex-wrap: wrap;
  font-size: 1.2rem;
}

/* Styling for the contact info section */
.contact__info {
  flex: 1;
  max-width: 540px;
}

.contact__address-container {
  padding-left: 5vw;
  margin-top: 20px;
}

.contact_info_text {
  margin-bottom: 10px;
}
.contact__address {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  font-size: 2rem;
  color: #66bb6a;
}

/* Styling for the form */
.contact__form {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 440px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group-preferred {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

input, textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

/* Submit button */
.cta {
  padding: 10px;
  background-color: #66bb6a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
}

.cta:hover {
  background-color: #5aaa5c;
}

/* Thank you message styling */
.thank-you {
  font-size: 1.2rem;
  color: green;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .contact {
    flex-direction: column;
    align-items: center;
  }

  .contact__form {
    width: 100%;
    max-width: 440px;
  }
}

/* Styling for checkboxes to look modern */
.checkbox-group {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #f0f0f0;
  padding: 8px 12px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.checkbox-group:hover {
  background-color: #e0e0e0;
}

.checkbox-group input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #66bb6a;
  border-radius: 4px;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkbox-group input[type="checkbox"]:checked {
  background-color: #66bb6a;
  border-color: #5aaa5c;
  position: relative;
}

.checkbox-group input[type="checkbox"]:checked::after {
  content: "✔";
  color: white;
  font-size: 14px;
  position: absolute;
  top: 2px;
  left: 3px;
}

.checkbox-group label {
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}
