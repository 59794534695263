.areas-served {
  padding: 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.map-container {
  margin-top: 30px;
  max-width: 900px; /* Increased width */
  height: 60vh; /* Increased height */
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .map-container {
    margin-top: 30px;
    max-width: 900px; /* Increased width */
    height: 40vh; /* Increased height */
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
}

iframe {
  border: none;
  width: 100%;
  height: 100%;
}
