/* Services Section */
.services {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem; /* Adjusted for small screens */
  box-sizing: border-box;
}

/* Overlay for the content */
.services-overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Slightly darker for better text contrast */
  color: white;
  padding: 1rem 3rem;
  border-radius: 10px;
  width: 90%;
  max-width: 900px;
  text-align: left; /* Aligning text for better readability */
  box-sizing: border-box;
}

/* Headings and Paragraphs */
.services h2 {
  font-size: 3rem; /* Bigger headline for better visual hierarchy */
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: center; /* Center-align the heading */
}

.services p {
  font-size: 1.3rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  text-align: center; /* Keeps the paragraph aligned to the heading */
}

/* Service List */
.services ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.services li {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
/* Optional Separator */
.services li::before {
  content: "•"; /* Adds a subtle bullet point */
  color: #66bb6a;
  font-size: 1.5rem;
}

/* Button Container */
.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.solid-button {
  background-color: #66bb6a;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.solid-button:hover {
  background-color: #388e3c;
}

.hollow-button {
  background-color: transparent;
  color: #66bb6a;
  border: 2px solid #66bb6a;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hollow-button:hover {
  background-color: #388e3c;
  border: 2px solid #388e3c;
  color: white;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .services-overlay {
    width: 100%;
    padding: 2rem;
    text-align: left;
  }

  .services h2 {
    font-size: 2.5rem;
  }

  .services p {
    font-size: 1.1rem;
  }

}
