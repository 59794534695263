.quote {
  padding: 10px;
  background-color: #fafafa;
  text-align: center;
  min-height: 50vh;
  font-size: 1.2rem;
}

.quote form {
  display: flex;
  flex-direction: column;
  gap: 1px;
  max-width: 400px;
  margin: 0 auto;
}

.quote input,
.quote button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.quote select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.quote button {
  margin-top: 20px;
  background-color: #66bb6a;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 2%;
}

.quote button:hover {
  background-color: #5aaa5c;
}

.fence-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  font-weight: bold;
}

.fence-option {
  cursor: pointer;
  border: 4px solid transparent;
  transition: border 0.3s ease;
  width: 150px;
  text-align: center;
  border-radius: 10px;
}

.fence-option.selected {
  border: 4px solid #66bb6a;
}
.fence-option p {
  margin: 2px;
}

.fence-option img {
  width: 100%;
  height: 100px;
  border-radius: 5px;
}

.gate-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2.5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

label{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

