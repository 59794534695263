.reviews {
  min-height: 40em;
  background-image: url('../../assets/wet-grass.jpg'); 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3vw;
  overflow: hidden;
}

@media (max-width: 768px) {
  .reviews {
    background-size: auto; /* Adjust background for smaller screens */
    padding: 20px 10px;
  }
}

.reviews-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  max-width: 1200px;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
}

.reviews-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Flexible grid */
  gap: 20px;
  margin-top: 20px;
}

.review-card img {
  max-width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.review-card p {
  font-size: 1rem;
  margin: 10px 0;
}

