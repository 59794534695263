/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5vh;
  min-height: 10vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../assets/houselandscape.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  
}

.header-title {
  font-size: 3vh;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  color: white;
}
@media (max-width: 768px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: end;
    flex-grow: 1; /* Push the nav links to the edge */
  }
}


.nav-links button {
  background: none;
  border: none;
  color: white;
  margin: 0 15px;
  font-size: 2vh;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
}

.nav-links button:hover {
  color: #b3ffb3;
}

.nav-links button::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: #b3ffb3;
  transition: width 0.3s;
  margin-top: 5px;
}

.nav-links button:hover::after {
  width: 100%;
}

/* Smooth scrolling behavior */
html {
  scroll-behavior: smooth;
}
