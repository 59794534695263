.recent-work {
  min-height: 30em;
  padding: 1.5vw 3vw;
  background-color: #e8f5e9;
  text-align: center;
}

.recent-work h2 {
  margin-top: 5px;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 10px; /* Adjust padding to bring arrows closer */
}

.arrow {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  transition: transform 0.2s;
}

.arrow:hover {
  transform: scale(1.2);
}

.work-container {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
}

.work-item {
  width: 45%; 
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s;
}

.work-item:hover {
  transform: translateY(-8px);
}

.images {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.images img {
  width: calc(50% - 5px); /* Ensure two images fit side-by-side */
  height: auto;
  border-radius: 8px;
}

/* Carousel Indicators */
.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 8px;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: black;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .work-item {
    width: 90%; /* Full width on mobile */
    padding: 15px;
  }

  .images img {
    width: 100%; /* Stack images vertically on mobile */
    margin-bottom: 10px;
  }

  .carousel {
    padding: 0; /* Remove extra padding for mobile */
  }

  .arrow {
    transform: scale(0.9); /* Slightly smaller arrows */
  }
}
