.about {
  padding: 50px 20px;
  background-color: #6d4c41;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  text-align: center;
  margin-bottom: 30px;
}

.banner h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
}

.underline {
  color: white;
  text-decoration: underline;
  text-decoration-color: #66bb6a;
  text-decoration-thickness: 5px;
}

.about-container {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.about-container img {
  width: 100%;
  max-width: 450px;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.about-text {
  text-align: left;
  color: white;
  max-width: 600px;
}
.h1 {
  color: white;
}

.about-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.about-text p {
  font-size: 1.2rem;
  margin-bottom: 15px;
  line-height: 1.6;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    text-align: center;
  }

  .about-container img {
    width: 80%;
  }

  .about-text {
    text-align: center;
  }

  .about-text h2 {
    font-size: 1.5rem;
  }
}

